import React from 'react'
import Layout from '../components/layout'
import SocialMediaBox from '../components/social-media-box'



export default function HomePage () {

  return (

    <Layout>
      <h1>Page Not Found</h1>

      <SocialMediaBox />

    </Layout>

  )
}